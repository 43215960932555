import React from "react"
import _ from "lodash"
import { graphql, Link } from "gatsby"
import HTMLRenderer from "react-html-renderer"
import Layout from "../components/layout"
import "../scss/index.scss"

export const query = graphql`
  query BlogPostQuery($uid: String) {
    prismicBlogPost(uid: { eq: $uid }) {
      id
      uid
      tags
      data {
        title {
          text
        }
        subtitle {
          text
        }
        release_date(formatString: "MMMM DD YYYY")
        author {
          document {
            ... on PrismicAuthor {
              id
              data {
                name {
                  text
                }
              }
              dataString
            }
          }
        }
        body {
          __typename
          ... on PrismicBlogPostBodyTextField {
            slice_type
            slice_label
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicBlogPostBodyImage {
            id
            slice_label
            slice_type
            primary {
              caption {
                text
              }
              image {
                fluid {
                  src
                }
              }
            }
          }
          ... on PrismicBlogPostBodyQuote {
            id
            slice_label
            slice_type
            primary {
              quote {
                text
              }
            }
          }
        }
      }
    }
  }
`

const BlogSlices = function BlogSlices({ slices }) {
  if (slices == null || slices.length === 0) {
    return []
  }

  const renderedSlices = slices.map((slice, index) => {
    switch (slice.slice_type) {
      case "text_field":
        return (
          <div key={index}>
            <HTMLRenderer html={slice.primary.text.html} />
          </div>
        )
      case "quote":
        return (
          <div key={index}>
            <div className="block-quote">{slice.primary.quote.text}</div>
          </div>
        )
      case "image":
        return (
          <div key={index}>
            <img className="block-image" src={slice.primary.image.fluid.src} />
          </div>
        )
      default:
        return null
    }
  })

  return _.compact(renderedSlices)
}

export default function SingleBlog({ data: { prismicBlogPost: blogPost } }) {
  if (blogPost == null) {
    return <Layout />
  }

  return (
    <Layout>
      <div className="blog-container">
        <div className="blog-title">{blogPost.data.title.text}</div>
        <div className="blog-subtitle">{blogPost.data.subtitle.text}</div>
        <div className="blog-author-date">
          <div className="blog-author">
            by <strong>{blogPost.data.author.document.data.name.text}</strong> ∙
            <span className="blog-date">{blogPost.data.release_date}</span>
          </div>
        </div>
        <div className="blog-content">
          <BlogSlices slices={blogPost.data.body} />
        </div>
        <div className="line" />
        {blogPost.tags != null && blogPost.tags.length !== 0 && (
          <div className="tag-container">
            {blogPost.tags.map(node => (
              <Link to={"/stories"} key={node}>
                <div className="tag">{node}</div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </Layout>
  )
}
